/**
 * Manages Global Scope information across the UI.
 *
 * Wait for the User and UserSettings records to be ready,
 * as both Root Place and Current Place information is
 * needed before this composable will query any data.
 *
 * Watch for `isReady` to be true then check if migrations
 * need to be ran on the schema data; or create a
 * default record if needed.
 *
 * When we have the current scope information from the
 * UserSetting two things occur. The information is
 * duplicated into the `current` variable, and the
 * `isLatest` computed will check if `current` is
 * the same value as what is in the UserSetting.
 * This allows us to have 2 or more browsers
 * open at different Scopes.
 */
import { UserSettingsName, UserSettingValue, ScopeSettingMigrationMeta } from "../use-user-settings/types";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import { reactive, ref, unref, UnwrapNestedRefs, watch } from 'vue';
import type { useUserSettingsReturn } from "../use-user-settings";
import { MigrationImport, ScopePlace } from "@/types";
import { cloneDeep } from "@apollo/client/utilities";
import { qx_ePlaceType_enum } from "@/graphql/types";
import { ApolloClient } from "@/utils/ApolloClient";
import { useNotifyStore } from '@/store/notify';
import { createEventHook } from "@vueuse/core";
import { useUserReturn } from "./../use-user";
import Migrations from "@/utils/Migrations";
import { gql } from "@apollo/client/core";
import { v2Schema } from "./types";
import { computed } from 'vue';

const PlaceScopeFragment = gql`
  fragment PlaceScopeFragment on qx_Place {
    id
    name
    path
    type
    requireTwoFactor
    toHomePlaces {
      integratorPlaceID
      customerPlaceID
      homePlace {
        id
        name
        type
        path
        canSelfServe
        requireTwoFactor
      }
    }
  }
`;

const GQL_GET_USER_SCOPES = gql`
  query GQL_GET_USER_SCOPES($ids: [String!]) {
    places(where: {
      id: {
        _in: $ids
      }
    }) {
      ...PlaceScopeFragment
    }
  }
  ${PlaceScopeFragment}
`

const GQL_SUB_USER_SCOPES = gql`
  subscription GQL_SUB_USER_SCOPES($ids: [String!]) {
    places(where: {
      id: {
        _in: $ids
      }
    }) {
      ...PlaceScopeFragment
    }
  }
  ${PlaceScopeFragment}
`

const GQL_GET_PLACE_TO_SET_CURRENT_SCOPE = gql`
  query GQL_GET_PLACE_TO_SET_CURRENT_SCOPE($id: String!) {
    place(id: $id) {
      ...PlaceScopeFragment
    }
  }
  ${PlaceScopeFragment}
`

// TODO :: can we trust homePlace?
const BlankScopePlace: ScopePlace = {
  id: '',
  name: '',
  path: '',
  type: qx_ePlaceType_enum.Unknown,
  toHomePlaces: {
    integratorPlaceID: null,
    customerPlaceID: null,
    homePlace: {
      id: '',
      name: '',
      path: '',
      type: qx_ePlaceType_enum.Unknown,
      canSelfServe: false
    },
  },
}

// A list of available migrations. No files are downloaded by the browser unless needed.
const migrations = import.meta.glob<MigrationImport<ScopeSettingMigrationMeta, UserSettingValue>>('./migrations/*.ts')

export function useUserScope(
  user: useUserReturn,
  settings: UnwrapNestedRefs<useUserSettingsReturn>
) {
  const rootID = computed(() => user.data.value?.placeID)
  const currentID = computed(() => settings.data?.[UserSettingsName.CurrentScope]?.value?.placeID)
  const notify = useNotifyStore();

  /**
   * Whenever the User changes their scope it causes the UI to recreate the component
   * tree for most of the app, this means that pages within are not aware that a
   * scope change occurred. There may be times where we want a page to perform
   * an action or unique cleanup when a scope change occurs, or when a scope
   * change goes up or down the hierarchy. This event hook allows for pages
   * to listen for these events and respond accordingly without coupling
   * this global store logic to view-specific logic.
   */
  const eventHook = createEventHook<{
    type: string,
    value: {
      from: ScopePlace,
      to: ScopePlace
    }
  }>();

  // Prepare migrations and get latest version info
  const { version, migrate } = Migrations<ScopeSettingMigrationMeta, UserSettingValue>(migrations);

  const isReady = computed(() => {
    return user.isReady.value && settings.isReady && rootID.value
  });

  const hasRanMigrations = ref(false);

  // Once the UserSettings have loaded, check if migrations need to be ran.
  watch(isReady, async (ready) => {

    if (!ready && hasRanMigrations.value) {
      hasRanMigrations.value = false;
      return;
    }

    if (!ready || hasRanMigrations.value) {
      return;
    }

    try {
      const meta = {
        personID: user.data.value.id,
        placeID: user.data.value.placeID,
      };

      // If no Setting record exists, create record.
      const hasSetting = settings.data?.[UserSettingsName.CurrentScope]
      if (!hasSetting) {
        const latestDefaultSetting = await migrate(meta)
        if (!latestDefaultSetting) {
          throw 'Could not create default Current Scope Setting record. Please contact support.'
        }
        await settings.data.create(UserSettingsName.CurrentScope, latestDefaultSetting);
        return;
      }

      // if on latest setting, do nothing
      if (hasSetting.value.schemaVersion === version && hasSetting.value.placeID) return;

      // If Setting record exists, run migrations if needed.
      const hasUpdate = await migrate(meta, hasSetting.value)
      if (hasUpdate) {
        await hasSetting.update(hasUpdate)
        return;
      }

      // if missing placeID in UserSetting, set and update
      if (!hasSetting.value.placeID) {
        await hasSetting.update({ ...hasSetting.value, placeID: rootID.value })
      }


    } catch (error) {
      console.error('Error occurred setting up', {error})
      notify.error('Error occurred setting up')
    } finally {
      hasRanMigrations.value = true;
    }
  });

  const variables = computed(() => {
    const notReady = !rootID.value || !currentID.value || !settings.isReady;
    return {
      ids: notReady ? [] : Array.from(new Set([rootID.value, currentID.value]))
    }
  });

  const options = computed(() => ({
    enabled: !!variables.value.ids.length
  }));

  /**
   * Query will not run until the root scope
   * is known and the current scope setting
   * record is known.
   */
  const { result, loading, subscribeToMore } = provideApolloClient(ApolloClient)(() =>
    useQuery(
      GQL_GET_USER_SCOPES,
      variables,
      options
    )
  );

  subscribeToMore(() => ({
    document: GQL_SUB_USER_SCOPES,
    variables: variables.value
  }))

  const root = computed(() => {
    if (!rootID.value) return BlankScopePlace;
    return result.value?.places?.find?.(({ id }: { id: string }) => {
      return id === rootID.value
    }) || BlankScopePlace
  });

  /**
   * Keep track of the latest saved "current scope" from the database.
   * Compare against `current` value to determine if current browsing
   * session is not in-sync with database.
   */
  const _currentUpdated = ref(false);
  const _current = computed(() => {
    if (!currentID.value || !result.value?.places?.length) return BlankScopePlace;
    _currentUpdated.value = true;
    return result.value?.places?.find(({ id }: { id: string }) => {
      return id === currentID.value
    }) || root.value || BlankScopePlace // fallback to root if current is not found
  });

  /**
   * We want to keep the current session scope out-of-sync
   * with the database. This allows the User to have two
   * or more tabs open with unique scopes.
   */
  const current = reactive<ScopePlace>({...BlankScopePlace});
  const _update = ref(true);
  watch(() => [_current.value, _update.value],
    ([c, u], pv) => {
      if (!c.id || !u) return;
      if (JSON.stringify(c) === JSON.stringify(current)) return;
      _setCurrent(c);
      _update.value = false
    },
  { deep: true, immediate: true})

  function _setCurrent(incoming: ScopePlace) {
    _currentUpdated.value = false;
    const clone = cloneDeep(incoming);
    for (const key of Object.keys(current)) {
      if (clone?.[key]) {
        current[key] = clone[key];
      }
    }
  }

  /**
   * Will update the UserSetting record
   * for Current Scope and return the
   * Place record upon success.
   */
  const setCurrentScope = async (placeID: string) => {
    try {

      const response = await ApolloClient.query({
        query: GQL_GET_PLACE_TO_SET_CURRENT_SCOPE,
        variables: { id: placeID },
        fetchPolicy: 'cache-first'
      })

      if (!response?.data?.place) {
        throw new Error(`Could not find Place with ID: ${placeID}`)
      }

      await eventHook.trigger({ type: 'scope-change', value: {
        from: unref(current),
        to: response?.data?.place
      }});

      await settings.data[UserSettingsName.CurrentScope].update<v2Schema>({
        placeID,
        schemaVersion: version,
      })

      _setCurrent(response.data.place);
      // _update.value = true;

      return response.data.place;

    } catch (error) {
      throw error
    }
  }

  /**
   * Will update the UserSetting record
   * for Current Scope and return the
   * Place record upon success.
   */
  const incrementScope = async () => {
    try {
      // if at root, do nothing
      if (root.value.id === current.id) return;
      const currentScopeArray = current.path.split('.');
      const id = currentScopeArray[currentScopeArray.length - 3]
      return await setCurrentScope(id)

    } catch (error) {
      throw error
    }
  }

  /**
   * Determine if the User is using the latest Current Scope.
   * This can be used as a boolean indicator by the UI to
   * determine if the User is using the latest scope.
   */
  const isLatest = computed({
    get() {
      /**
       * When the user manually sets their scope, the UserSetting
       * will take a moment to update from the database, this
       * prevents the "isLatest" visual from appearing for
       * a brief moment.
       */
      if (!_currentUpdated.value) return true;

      // if the current scope is not set, do not inform the UI
      const latestID = _current.value.id
      const latestPath = _current.value.path
      if (!latestID || !latestPath) return true;

      const curID = current.id
      const curPath = current.path
      return (curID === latestID) && (curPath === latestPath)
    },
    set() {
      return true;
    }
  });

  // if there was a previous personID and it does not match the new, assume a new user logged in and we want to reset state
  watch(() => user.data.value.id, (personID: string) => {
    if (!personID) {
      hasRanMigrations.value = false;
      _currentUpdated.value = false;
      _update.value = true;
      Object.assign(current, BlankScopePlace);
    }
  })

  return {
    loading,
    root,
    current,
    on: eventHook.on,
    set: setCurrentScope,
    increment: incrementScope,
    reset: async () => {
      if (root.value.id === current.id) return;
      await setCurrentScope(root.value.id)
    },
    isReady: computed({
      get() {
        return !!root.value.id && !!current.id
      },
      set() {
        return true
      }
    }),
    isLatest,
    updateToLatest: () => _update.value = true,
    isPendingUpdate: computed({
      get() {
        return !isLatest.value && _update.value;
      },
      set() {
        return true;
      }
    }),
  }
}
