import { library } from '@fortawesome/fontawesome-svg-core';
// TODO :: change to glob import to stop updating an index file
// https://vitejs.dev/guide/faatures.html#glob-import
import * as QxIcons from './custom';

import {
  faBookmark as faBookmarkL,
  faCodeBranch as faCodeBranchL,
  faUser as faUserL,
  faUsers as faUsersL,
  faFingerprint as faFingerprintL,
  faCircleUser as faCircleUserL,
  faDoorOpen as faDoorOpenL,
  faMagnifyingGlass as faMagnifyingGlassL,
  faLocationDot as faLocationDotL,
  faChartSimple as faChartSimpleL,
  faGear as faGearL,
  faKey as faKeyL,
  faHardDrive as faHardDriveL,
  faSensor as faSensorL,
  faVideo as faVideoL,
  faCalendarAlt as faCalendarAltL,
  faIslandTropical as faIslandTropicalL,
  faEye as faEyeL,
  faChartTreeMap as faChartTreeMapL,
  faCalendarStar as faCalendarStarL,
  faSliders as faSlidersL,
  faCircleQuestion as faCircleQuestionL,
  faPuzzle as faPuzzleL,
  faLaptopMobile as faLaptopMobileL,
  faUserShield as faUserShieldL,
  faShieldCheck as faShieldCheckL,
  faShieldKeyhole as faShieldKeyholeL,
  faHdd as faHddL,
  faUserMagnifyingGlass as faUserMagnifyingGlassL,
  faFolderMagnifyingGlass as faFolderMagnifyingGlassL,
} from '@fortawesome/pro-light-svg-icons';

import {
  faCircle as faCircleR,
  faSquare as faSquareR,
  faCircleDot as faCircleDotR,
  faCalendarDays as faCalendarDaysR,
  faFloppyDisk as faFloppyDiskR,
  faClipboard as faClipboardR,
  faCodeBranch as faCodeBranchR,
  faShieldHalved as faShieldHalvedR,
  faPlay as faPlayR,
  faBookmark as faBookmarkR,
  faSort as faSortR,
  faSortUp as faSortUpR,
  faSortDown as faSortDownR,
  faTrashCan as faTrashCanR,
  faArrowRightFromArc as faArrowRightFromArcR,
  faArrowRightToArc as faArrowRightToArcR,
  faCalendarExclamation as faCalendarExclamationR,
  faVideoPlus as faVideoPlusR,
  faSearchPlus as faSearchPlusR,
  faTurtle as faTurtleR,
  faCircleInfo as faCircleInfoR,
  faSquareInfo as faSquareInfoR,
  faVideo as faVideoR,
  faExpand as faExpandR,
  faArrowsMaximize as faArrowsMaximizeR,
  faFilterList as faFilterListR,
  faSliders as faSlidersR,
  faClock as faClockR,
  faShieldKeyhole as faShieldKeyholeR,
  faBallotCheck as faBallotCheckR,
  faCirclePlay as faCirclePlayR,
  faCircleCheck as faCircleCheckR,
  faSquareChevronRight as faSquareChevronRightR,
} from '@fortawesome/pro-regular-svg-icons';

import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import {
  faDownload as faDownloadS,
  faRunning as faRunningS,
  faGripLinesVertical as faGripLinesVerticalS,
  faGrin as faGrinS,
  faWallet as faWalletS,
  faHandHolding as faHandHoldingS,
  faArrowLeft as faArrowLeftS,
  faArrowRight as faArrowRightS,
  faBuilding as faBuildingS,
  faVideo as faVideoS,
  faVideoSlash as faVideoSlashS,
  faBan,
  faCloud as faCloudS,
  faCircle as faCircleS,
  faSlash,
  faQuestion,
  faMinus,
  faXmark as faXmarkS,
  faSquare,
  faSpinner,
  faExclamation,
  faFileContract as faFileContractS,
  faTriangleExclamation,
  faRotateRight as faRotateRightS,
  faBars as faBarsS,
  faCircleInfo as faCircleInfoS,
  faCircleQuestion as faCircleQuestionS,
  faSitemap,
  faNetworkWired as faNetworkWiredS,
  faPlus as faPlusS,
  faEthernet as faEthernetS,
  faSquarePlus as faSquarePlusS,
  faPlusCircle as faPlusCircleS,
  faCaretLeft as faCaretLeftS,
  faCaretRight as faCaretRightS,
  faCaretDown as faCaretDownS,
  faCaretUp as faCaretUpS,
  faArrowDownShortWide as faArrowDownShortWideS,
  faArrowUpShortWide as faArrowUpShortWideS,
  faPenToSquare as faPenToSquareS,
  faScrewdriverWrench as faScrewdriverWrenchS,
  faEye as faEyeS,
  faEyeSlash as faEyeSlashS,
  faWifi as faWifiS,
  faBolt as faBoltS,
  faHandPointer as faHandPointerS,
  faEllipsisVertical as faEllipsisVerticalS,
  faToggleOff as faToggleOffS,
  faToggleOn as faToggleOnS,
  faCircleCheck as faCircleCheckS,
  faUnlockAlt as faUnlockAltS,
  faCircleMinus as faCircleMinusS,
  faGear as faGearS,
  faGears as faGearsS,
  faIdCardClip as faIdCardClipS,
  faFilter as faFilters,
  faClock as faClockS,
  faImage as faImageS,
  faImagePortrait as faImagePortraitS,
  faAsterisk as faAsteriskS,
  faPrint as faPrintS,
  faUserSlash as faUserSlashS,
  faCopy as faCopyS,
  faArrowRotateLeft as faArrowRotateLeftS,
  faCalendarDay as faCalendarDayS,
  faCalendarWeek as faCalendarWeekS,
  faCalendarCheck as faCalendarCheckS,
  faShareFromSquare as faShareFromSquareS,
  faCloudArrowDown as faCloudArrowDownS,
  faCloudArrowUp as faCloudArrowUpS,
  faClipboard as faClipboardS,
  faUserPlus as faUserPlusS,
  faHourglassStart as faHourglassStartS,
  faHourglassEnd as faHourglassEndS,
  faHourglass as faHourglassS,
  faFloppyDisk as faFloppyDiskS,
  faSquareCheck as faSquareCheckS,
  faBookmark as faBookmarkS,
  faEnvelope as faEnvelopeS,
  faSignInAlt as faSignInAltS,
  faRightFromBracket as faRightFromBracketS,
  faPaperPlane as faPaperPlaneS,
  faFileSignature as faFileSignatureS,
  faUserLock as faUserLockS,
  faMagnifyingGlass as faMagnifyingGlassS,
  faCircleArrowDown as faCircleArrowDownS,
  faTable as faTableS,
  faCheck as faCheckS,
  faChevronDown as faChevronDownS,
  faChevronUp as faChevronUpS,
  faChevronRight as faChevronRightS,
  faEarthAfrica as faEarthAfricaS,
  faEarthAmericas as faEarthAmericasS,
  faEarthAsia as faEarthAsiaS,
  faEarthEurope as faEarthEuropeS,
  faCodeBranch as faCodeBranchS,
  faLayerGroup as faLayerGroupS,
  faCode as faCodeS,
  faUsers as faUsersS,
  faUserCheck as faUserCheckS,
  faUserShield as faUserShieldS,
  faUserGear as faUserGearS,
  faPlay as faPlayS,
  faEquals as faEqualsS,
  faNotEqual as faNotEqualS,
  faGreaterThan as faGreaterThanS,
  faGreaterThanEqual as faGreaterThanEqualS,
  faLessThan as faLessThanS,
  faLessThanEqual as faLessThanEqualS,
  faMobile as faMobileS,
  faStopwatch as faStopwatchS,
  faRecycle as faRecycleS,
  faVolumeXmark as faVolumeXmarkS,
  faHardDrive as faHardDriveS,
  faCircleUser as faCircleUserS,
  faHouse as faHouseS,
  faDoorClosed as faDoorClosedS,
  faDoorOpen as faDoorOpenS,
  faUpload as faUploadS,
  faTruck as faTruckS,
  faBriefcase as faBriefcaseS,
  faFileLines as faFileLinesS,
  faSort as faSortS,
  faSortUp as faSortUpS,
  faSortDown as faSortDownS,
  faFileVideo as faFileVideoS,
  faKey as faKeyS,
  faStamp as faStampS,
  faUser as faUserS,
  faAngleRight as faAngleRightS,
  faAngleDown as faAngleDownS,
  faAngleUp as faAngleUpS,
  faChartSimple as faChartSimpleS,
  faCalendarAlt as faCalendarAltS,
  faTimeline as faTimelineS,
  faScissors as faScissorsS,
  faSquareMinus as faSquareMinusS,
  faPowerOff as faPowerOffS,
  faSquareUpRight as faSquareUpRightS,
  faHdd as faHddS,
  faBoltLightning as faBoltLightningS,
  faFire as faFireS,
  faTriangle,
  faHexagon as faHexagonS,
  faHorizontalRule as faHorizontalRuleS,
  faSpinnerThird,
  faSquareRight as faSquareRightS,
  faSquareLeft as faSquareLeftS,
  faLockKeyhole as faLockKeyholeS,
  faPianoKeyboard as faPianoKeyboardS,
  faUpRightFromSquare as faUpRightFromSquareS,
  faTrash as faTrashS,
  faBringForward as faBringForwardS,
  faRectangleBarcode as faRectangleBarcodeS,
  faShield as faShieldS,
  faShieldCheck as faShieldCheckS,
  faShieldExclamation as faShieldExclamationS,
  faLongArrowLeft as faLongArrowLeftS,
  faArrowsRetweet as faArrowsRetweetS,
  faBellExclamation as faBellExclamationS,
  faLink as faLinkS,
  faLocationCrosshairs as faLocationCrosshairsS,
  faRepeat1Alt as faRepeat1AltS,
  faMessageSms as faMessageSmsS,
  faLockAlt as faLockAltS,
  faLockKeyholeOpen as faLockKeyholeOpenS,
  faBoltSlash as faBoltSlashS,
  faBellOn as faBellOnS,
  faCloudSlash as faCloudSlashS,
  faLocationDot as faLocationDotS,
  faIslandTropical as faIslandTropicalS,
  faCalendarDays as faCalendarDaysS,
  faHouseBuilding as faHouseBuildingS,
  faGrid as faGridS,
  faGrid2 as faGrid2S,
  faChartTreeMap as faChartTreeMapS,
  faListTree as faListTreeS,
  faCalendarStar as faCalendarStarS,
  faForward as faForwardS,
  faBackward as faBackwardS,
  faForwardStep as faForwardStepS,
  faBackwardStep as faBackwardStepS,
  faFastBackward as faFastBackwardS,
  faSearchPlus as faSearchPlusS,
  faPause as faPauseS,
  faCircleCaretLeft as faCircCaretLeftS,
  faCircleCaretRight as faCircleCaretRightS,
  faServer as faServerS,
  faTurtle as faTurtleS,
  faFileCertificate as faFileCertificateS,
  faSensorCloud as faSensorCloudS,
  faHomeLgAlt as faHomeLgAltS,
  faOctagon as faOctagonS,
  faLightEmergency as faLightEmergencyS,
  faLightEmergencyOn as faLightEmergencyOnS,
  faBells as faBellsS,
  faMapMarkerAlt as faMapMarkerAltS,
  faPuzzle as faPuzzleS,
  faSliders as faSlidersS,
  faSlider as faSliderS,
  faLaptopMobile as faLaptopMobileS,
  faFingerprint as faFingerprintS,
  faMessageArrowDown as faMessageArrowDownS,
  faSensor as faSensorS,
  faSensorOn as faSensorOnS,
  faSensorAlert as faSensorAlertS,
  faBatterySlash as faBatterySlashS,
  faBatteryEmpty as faBatteryEmptyS,
  faBatteryQuarter as faBatteryQuarterS,
  faBatteryHalf as faBatteryHalfS,
  faBatteryThreeQuarters as faBatteryThreeQuartersS,
  faBatteryFull as faBatteryFullS,
  faGearCode as faGearCodeS,
  faArrowsRotate as faArrowsRotateS,
  faRotateExclamation as faRotateExclamationS,
  faCircleExclamation as faCircleExclamationS,
  faCircleXmark as faCircleXmarkS,
  faShieldKeyhole as faShieldKeyholeS,
  faDownFromLine as faDownFromLineS,
  faUpToLine as faUpToLineS,
  faBadgeCheck as faBadgeCheckS,
  faBellRing as faBellRingS,
  faBellSlash as faBellSlashS,
  faTimer as faTimerS,
  faBullhorn as faBullhornS,
  faSitemap as faSitemapS,
  faBarsStaggered as faBarsStaggeredS,
  faWrench as faWrenchS,
  faMemoCircleInfo as faMemoCircleInfoS,
  faBrowser as faBrowserS,
  faKeyboardDown as faKeyboardDownS,
  faArrowsToCircle as faArrowsToCircleS,
  faWindowMinimize as faWindowMinimizeS,
  faWindowRestore as faWindowRestoreS,
  faFileExport as faFileExportS,
  faRectangleTerminal as faRectangleTerminalS,
  faClockRotateLeft as faClockRotateLeftS,
  faUserSecret as faUserSecretS,
  faBanBug as faBanBugS,
  faWebhook as faWebhookS,
  faWater as faWaterS,
  faUserMagnifyingGlass as faUserMagnifyingGlassS,
  faFolderMagnifyingGlass as faFolderMagnifyingGlassS,
  faArrowRightFromArc as faArrowRightFromArcS,
  faArrowRightToArc as faArrowRightToArcS,
  faArrowsTurnToDots as faArrowsTurnToDotsS,
  faObjectGroup as faObjectGroupS,
  faWaveformLines as faWaveformLinesS,
  faShapes as faShapesS,
  faChartScatter3d as faChartScatter3dS,
  faWindowMaximize as faWindowMaximizeS,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faSpinnerThird as faSpinnerThirdD,
  faDoorOpen as faDoorOpenD,
  faVideo as faVideoD,
  faBarsProgress as faBarsProgressD,
  faShieldKeyhole as faShieldKeyholeD,
  faSlider as faSliderD,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
  // @ts-ignore
  faDownloadS,
  faFireS,
  faServerS,
  faRotateRightS,
  faBan,
  faBarsS,
  faCircleQuestionS,
  faSitemap,
  faNetworkWiredS,
  faQuestion,
  faSpinnerThird,
  faCircleS,
  faSlash,
  faSpinner,
  faMinus,
  faXmarkS,
  faExclamation,
  faSquare,
  faTriangle,
  faTriangleExclamation,
  faHexagonS,
  faPlusS,
  faSquarePlusS,
  faPlusCircleS,
  faSquareRightS,
  faSquareLeftS,
  faCaretLeftS,
  faCaretRightS,
  faCaretDownS,
  faCaretUpS,
  faArrowDownShortWideS,
  faArrowUpShortWideS,
  faPenToSquareS,
  faScrewdriverWrenchS,
  faEyeS,
  faEyeSlashS,
  faLockKeyholeS,
  faPianoKeyboardS,
  faWifiS,
  faBoltS,
  faUpRightFromSquareS,
  faEthernetS,
  faTrashS,
  faHandPointerS,
  faHorizontalRuleS,
  faToggleOffS,
  faToggleOnS,
  faUnlockAltS,
  faCircCaretLeftS,
  faCircleCaretRightS,
  faFileContractS,
  faCircleCheckS,
  faCircleMinusS,
  faGearS,
  faGearsS,
  faIdCardClipS,
  faFilters,
  faClockS,
  faImageS,
  faImagePortraitS,
  faAsteriskS,
  faPrintS,
  faUserSlashS,
  faLinkS,
  faCopyS,
  faArrowRotateLeftS,
  faCalendarDayS,
  faCalendarWeekS,
  faCalendarWeekS,
  faCalendarCheckS,
  faShareFromSquareS,
  faCloudArrowDownS,
  faCloudArrowUpS,
  faClipboardS,
  faUserPlusS,
  faHourglassStartS,
  faHourglassEndS,
  faHourglassS,
  faFloppyDiskS,
  faBringForwardS,
  faGridS,
  faSquareCheckS,
  faBookmarkS,
  faBringForwardS,
  faEnvelopeS,
  faShieldS,
  faShieldCheckS,
  faShieldExclamationS,
  faSignInAltS,
  faRightFromBracketS,
  faPaperPlaneS,
  faFileSignatureS,
  faUserLockS,
  faLongArrowLeftS,
  faVideoS,
  faCloudS,
  faVideoSlashS,
  faVideoPlusR,
  faMagnifyingGlassS,
  faCircleArrowDownS,
  faTableS,
  faCheckS,
  faChevronDownS,
  faChevronUpS,
  faChevronRightS,
  faEarthAfricaS,
  faEarthAmericasS,
  faEarthAsiaS,
  faEarthEuropeS,
  faCodeBranchS,
  faMessageSmsS,
  faBellOnS,
  faLayerGroupS,
  faArrowsRetweetS,
  faCodeS,
  faBellExclamationS,
  faUsersS,
  faUserCheckS,
  faUserShieldS,
  faUserGearS,
  faPlayS,
  faEqualsS,
  faNotEqualS,
  faCircleInfoS,
  faEllipsisVerticalS,
  faGreaterThanS,
  faGreaterThanEqualS,
  faLessThanS,
  faLessThanEqualS,
  faMobileS,
  faLocationCrosshairsS,
  faStopwatchS,
  faRecycleS,
  faBoltSlashS,
  faRepeat1AltS,
  faVolumeXmarkS,
  faHardDriveS,
  faCircleUserS,
  faHouseS,
  faLockAltS,
  faLockKeyholeOpenS,
  faDoorClosedS,
  faDoorOpenS,
  faUploadS,
  faTruckS,
  faBriefcaseS,
  faFileLinesS,
  faRectangleBarcodeS,
  faSortS, // hyper-table usage
  faSortUpS, // hyper-table usage
  faSortDownS, // hyper-table usage
  faCloudSlashS, // Not connected tsx page
  faLocationDotS, // action menu
  faIslandTropicalS, // holiday set icon
  faCalendarDaysS, // used by old schedule components
  faFileVideoS,
  faKeyS,
  faStampS,
  faUserS,
  faHouseBuildingS,
  faGrid2S,
  faAngleRightS,
  faAngleDownS,
  faAngleUpS,
  faChartSimpleS,
  faChartTreeMapS,
  faListTreeS,
  faCalendarAltS,
  faCalendarStarS,
  faTimelineS,
  faForwardS,
  faBackwardS,
  faForwardStepS,
  faBackwardStepS,
  faFastBackwardS,
  faSearchPlusS,
  faPauseS,
  faScissorsS,
  faSquareMinusS,
  faTurtleS,
  faPowerOffS,
  faSquareUpRightS,
  faFileCertificateS,
  faSensorCloudS,
  faHddS,
  faBuildingS,
  faHomeLgAltS,
  faOctagonS,
  faLightEmergencyS,
  faLightEmergencyOnS,
  faBellsS,
  faBoltLightningS,
  faRunningS,
  faGripLinesVerticalS,
  faGrinS,
  faWalletS,
  faHandHoldingS,
  faArrowLeftS,
  faArrowRightS,
  faMapMarkerAltS,
  faPuzzleS,
  faSlidersS,
  faSliderS,
  faLaptopMobileS,
  faFingerprintS,
  faMessageArrowDownS,
  faBatterySlashS,
  faBatteryEmptyS,
  faBatteryQuarterS,
  faBatteryHalfS,
  faBatteryThreeQuartersS,
  faBatteryFullS,
  faGearCodeS,
  faArrowsRotateS,
  faCircleXmarkS,
  faShieldKeyholeS,
  faRotateExclamationS,
  faCircleExclamationS,
  faDownFromLineS,
  faUpToLineS,
  faSitemapS,
  faBadgeCheckS,
  faBellRingS,
  faBellSlashS,
  faTimerS,
  faBullhornS,
  faBarsStaggeredS,
  faWrenchS,
  faBrowserS,
  faMemoCircleInfoS,
  faKeyboardDownS,
  faArrowsToCircleS,
  faWindowMinimizeS,
  faWindowRestoreS,
  faFileExportS,
  faRectangleTerminalS,
  faClockRotateLeftS,
  faUserSecretS,
  faBanBugS,
  faWebhookS,
  faWaterS,
  faUserMagnifyingGlassS,
  faFolderMagnifyingGlassS,
  faArrowRightFromArcS,
  faArrowRightToArcS,
  faArrowsTurnToDotsS,
  faObjectGroupS,
  faWaveformLinesS,
  faShapesS,
  faChartScatter3dS,
  faWindowMaximizeS,

  // regular
  faVideoR,
  faVideoPlusR,
  faCircleR,
  faSquareR,
  faCircleDotR,
  faCodeBranchR,
  faCalendarDaysR,
  faShieldHalvedR,
  faPlayR,
  faFloppyDiskR,
  faBookmarkR,
  faSortR, // hyper-table usage
  faSortUpR, // hyper-table usage
  faSortDownR, // hyper-table usage
  faTrashCanR,
  faArrowRightFromArcR,
  faArrowRightToArcR,
  faCalendarExclamationR,
  faSearchPlusR,
  faArrowsMaximizeR,
  faExpandR,
  faTurtleR,
  faClipboardR,
  faCircleInfoR,
  faSquareInfoR,
  faFilterListR,
  faSlidersR,
  faClockR,
  faSensorS,
  faSensorOnS,
  faSensorAlertS,
  faShieldKeyholeR,
  faBallotCheckR,
  faCirclePlayR,
  faCircleCheckR,
  faSquareChevronRightR,

  // light
  faBookmarkL,
  faCodeBranchL,
  faUserL,
  faUsersL,
  faCircleUserL,
  faDoorOpenL,
  faMagnifyingGlassL,
  faLocationDotL,
  faChartSimpleL,
  faGearL,
  faKeyL,
  faHardDriveL,
  faSensorL,
  faVideoL,
  faCalendarAltL,
  faIslandTropicalL,
  faEyeL,
  faChartTreeMapL,
  faCalendarStarL,
  faSlidersL,
  faCircleQuestionL,
  faPuzzleL,
  faLaptopMobileL,
  faUserShieldL,
  faFingerprintL,
  faShieldCheckL,
  faShieldKeyholeL,
  faHddL,
  faUserMagnifyingGlassL,
  faFolderMagnifyingGlassL,

  // duotone
  faSpinnerThirdD,
  faDoorOpenD,
  faVideoD,
  faBarsProgressD,
  faShieldKeyholeD,
  faSliderD,

  // brands
  faGoogle,

  // @ts-ignore
  ...Object.values(QxIcons)
);

// square = create blank space for masking
// ban = not supported
// slash = disabled
// spinner = loader
// times = invalid
// exclamation = invalid
